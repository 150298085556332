const theme = {
  paddingY: "2rem",
  paddingX: "3rem",
}

export const lightTheme = {
  body: "#FAFAFA",
  primary: "#000000",
  secondary: "#F65058",
  ...theme,
  // toggleBorder: "#FFF",
  // gradient: "linear-gradient(#39598A, #79D7ED)",
}

export const darkTheme = {
  body: "#2C3640",
  primary: "#FFFFFF",
  secondary: "#FBE053",
  ...theme,
  // toggleBorder: "#6B8096",
  // gradient: "linear-gradient(#091236, #1E215D)",
}
